import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout title="about" topMargin={false}>
      <Container className="section text-center">
        <h3 className="text-center font-weight-lighter">
          Feel free to reach out or check out my publicly available work.
        </h3>
        <Row>
          <Col>
            <a href="mailto:jacob.spizziri@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </a>
          </Col>
          <Col>
            <a href="https://linkedin.com/in/jspizziri"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-linkedin-square" aria-hidden="true"></i>
            </a>
          </Col>
          <Col>
            <a href="https://github.com/jspizziri"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-github" aria-hidden="true"></i>
            </a>
          </Col>
          <Col>
            <a href="https://gitlab.com/jspizziri"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-gitlab" aria-hidden="true"></i>
            </a>
          </Col>
          <Col>
            <a href="https://stackoverflow.com/users/2811583/jspizziri"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-stack-overflow" aria-hidden="true"></i>
            </a>
          </Col>
          <Col>
            <a href="https://twitter.com/jspizziri"
              target="_blank"
              rel="noopener noreferrer"
              style={{ fontSize: '5rem' }}
            >
              <i className="fa fa-twitter" aria-hidden="true"></i>
            </a>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
